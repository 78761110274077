<template>
    <LiefengContent>
        <template v-slot:title>抽奖名单</template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true">
                <Button @click="clickBack" type="success">返回</Button>
            </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :total="total"
                :curPage="currentPage"
                :pagesizeOpts="[20, 30, 50, 100]"
                @hadlePageSize="pageChange"
                :loading="loading"
                :fixTable="true"
                :pageSize="pageSize"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/answerwinning')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
export default {
    data() {
        return {
            loading: false,
            //title
            //表头列表
            talbeColumns: [
                {
                    title: "街道",
                    key: "streetName",
                    minWidth: 140,
                    align: "center",
                },
                {
                    title: "社区",
                    key: "communityName",
                    minWidth: 140,
                    align: "center",
                },
                {
                    title: "姓名",
                    key: "username",
                    minWidth: 140,
                    align: "center",
                },
                {
                    title: "年龄",
                    key: "age",
                    minWidth: 140,
                    align: "center",
                },
                {
                    title: "性别",
                    key: "sex",
                    minWidth: 140,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.sex == 0 ? "未知" : params.row.sex == 1 ? "男" : params.row.sex == 2 ? "女" : "未知")
                    },
                },
                {
                    title: "联系电话",
                    key: "mobile",
                    width: 140,
                    align: "center",
                },
                {
                    title: "现住地址",
                    key: "addr",
                    width: 140,
                    align: "center",
                },
                {
                    title: "奖品",
                    key: "itemName",
                    width: 140,
                    align: "center",
                },
                {
                    title: "兑奖码",
                    key: "code",
                    width: 140,
                    align: "center",
                },
                {
                    title: "领取状态",
                    key: "recordStatus",
                    width: 140,
                    align: "center",
                },
                {
                    title: "领取时间",
                    key: "verifyTime",
                    width: 200,
                    align: "center",
                },
                {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    fixed: "right",
                    width: 120,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                        disabled: params.row.recordStatus == "未领取" ? false : true,
                                    },
                                    style: {},
                                    on: {
                                        click: () => {
                                            if (params.row.recordStatus == "未领取") {
                                                this.$Message.loading({
                                                    content: "正在操作，请稍等",
                                                    duration: 0,
                                                })
                                                this.$post("/gateway/api/sycomponent/pc/simplePrize/updateRecordStatus", {
                                                    code: params.row.code,
                                                })
                                                    .then(res => {
                                                        this.$Message.destroy()
                                                        if (res.code == 200) {
                                                            this.$Message.success({
                                                                background: true,
                                                                content: "发放成功",
                                                            })
                                                            this.publishStatus = false
                                                            this.pageChange({
                                                                page: this.currentPage,
                                                                pageSize: this.pageSize,
                                                            })
                                                        } else {
                                                            this.$Message.error({
                                                                background: true,
                                                                content: res.desc,
                                                            })
                                                        }
                                                    })
                                                    .catch(err => {
                                                        this.$Message.destroy()
                                                        this.$Message.error({
                                                            background: true,
                                                            content: "发放失败",
                                                        })
                                                    })
                                            }
                                        },
                                    },
                                },
                                params.row.recordStatus == "未领取" ? "发放" : "已处理"
                            ),
                        ])
                    },
                },
            ],
            tableData: [],
            total: 0,
            currentPage: 1,
            pageSize: 20,
        }
    },
    methods: {
        // 返回
        clickBack() {
            var index = parent.layer.getFrameIndex(window.name)
            if (index) {
                //先得到当前iframe层的索引
                parent.layer.close(index) //再执行关闭
            } else {
                this.$router.push("/newanswerindex?menuId=" + this.$route.query.menuId)
            }
        },
        //分页器
        async pageChange(data) {
            this.loading = true
            await this.$get("/gateway/api/sycomponent/pc/simplePrize/selectRecordByPage", {
                businessId: this.$route.query.id,
                page: data.page,
                pageSize: data.pageSize,
            }).then(res => {
                if (res.code == 200) {
                    res.dataList.map((item, index, arr) => {
                        item.recordStatus = item.recordStatus == "1" ? "未领取" : item.recordStatus == "2" ? "已领取" : "弃奖"
                        if (item.verifyTime) {
                            item.verifyTime = this.$core.formatDate(new Date(item.verifyTime), "yyyy-MM-dd hh:mm:ss")
                        }
                    })
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.currentPage = res.currentPage
                    this.pageSize = res.pageSize
                    this.loading = false
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                    this.loading = false
                    return
                }
            })
        },
    },

    created() {
        this.pageChange({
            page: this.currentPage,
            pageSize: this.pageSize,
        })
    },
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
    },
}
</script>
    
<style scoped lang='less'>
.eye {
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    margin: 0 15px 0;
}
</style>